/* eslint no-console: "off" */

import config from '../../config';

const isDev = config.env === 'development';
const devLogger = {
  info: (...args) => {
    if (isDev) {
      console.log(...args);
    }
  },
  error: (...args) => {
    if (isDev) {
      console.log(...args);
    }
  },
  debug: (...args) => {
    if (isDev) {
      console.log(...args);
    }
  },
};

export default devLogger;
