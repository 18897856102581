import { IDLE_TIMEOUT, IDLE_TIMEOUT_CONFIRMATION } from 'stores/metadata';

export function getIdleTimeoutValue() {
  if (IDLE_TIMEOUT < IDLE_TIMEOUT_CONFIRMATION) {
    return IDLE_TIMEOUT;
  }
  return IDLE_TIMEOUT - IDLE_TIMEOUT_CONFIRMATION;
}

export function stringifyIdleConfirmationValue(secondsLeft: number) {
  if (secondsLeft < 60) {
    return `${secondsLeft} sec`;
  }

  if (secondsLeft < 60 * 60) {
    const minutes = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft % 60;
    return `${minutes} min ${seconds < 10 ? '0' : ''}${seconds} sec`;
  }

  return 'about an hour';
}
