import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { useIdleTimer } from 'react-idle-timer';

import config from 'config';
import { UserStore, logoutAction, setUserHasLoaded } from 'stores/user';
import { initApiVersion } from 'stores/app-context';
import useAidaApi from 'services/hooks/useApiConfig';
import apiService from 'services/api';

import { ConfirmTimeout, Loading } from 'ui';
import MsalAuthenticationError from 'ui/molecules/MsalAuthenticationError';
import logger from 'services/logger';
import { Features } from 'stores/features';
import { getIdleTimeoutValue } from 'utils/time-utils';
import useIsFeatureEnabled from 'services/hooks/useIsFeatureEnabled';

const SetAuthed = ({ children }) => {
  const { createApiConfig } = useAidaApi();
  const { instance } = useMsal();
  const accountInfo = instance.getAllAccounts();

  useEffect(() => {
    (async () => {
      setTimeout(() => {
        createApiConfig().then((apiConfig) => {
          setUserHasLoaded(true, apiConfig, accountInfo[0]); // Assuming if you get here, you're authenticated
          apiService.setApiConfig(apiConfig);
          initApiVersion();
        });
      }, 500);
    })();
  }, [createApiConfig, accountInfo]);
  return <>{children}</>;
};

const AgreedToTerms = ({ children }) => {
  let navigate = useNavigate();

  const agreedToTerms = UserStore.useState((s) => s.agreedToTerms);
  useEffect(() => {
    if (!agreedToTerms && window.location.pathname !== '/terms') {
      navigate('/terms');
    }
  }, [agreedToTerms, navigate]);
  return <>{children}</>;
};

const ProtectedRoute = ({ children }) => {
  const isAuthed = UserStore.useState((s) => s.isAuthed);
  const { instance } = useMsal();
  const accountInfo = instance.getAllAccounts();

  const [confirmTimeoutOpen, setConfirmTimeoutOpen] = React.useState(false);
  const isIdleTimeoutActive = useIsFeatureEnabled(Features.IdleTimeout);

  async function logout() {
    logoutAction();
    const currentAccount = accountInfo[0];

    await instance.logoutRedirect({
      account: currentAccount,
    });
  }

  const { start, getRemainingTime, isIdle, isPrompted } = useIdleTimer({
    timeout: getIdleTimeoutValue(),
    onIdle: () => {
      setConfirmTimeoutOpen(true);
      logger.info('User has been idle for too long.  Starting timeout.');
    },
    debounce: 0,
    startManually: true,
  });
  (window as any).goldie = {
    getRemainingTime: getRemainingTime,
    idleCountdown: () => {
      logger.info((window as any).goldie.getRemainingTime());
      setInterval(() => {
        logger.info((window as any).goldie.getRemainingTime());
      }, 10000);
    },
    isIdle,
    isPrompted,
  };
  useEffect(() => {
    if (isAuthed && isIdleTimeoutActive) {
      logger.info('Starting idle timer');
      start();
    }
  }, [isAuthed, start, isIdleTimeoutActive]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{
        scopes: config.scopes,
        authority: `${config.microsoftLoginUrl}/${config.tenantId}`,
        redirectUri: window.location.origin,
      }}
      errorComponent={MsalAuthenticationError}
    >
      <SetAuthed>
        {!isAuthed ? (
          <Loading />
        ) : (
          <AgreedToTerms>
            {children}
            <ConfirmTimeout
              confirmTimeoutOpen={confirmTimeoutOpen}
              confirmTimeoutFunction={(confirmed) => {
                if (confirmed) {
                  start();
                  logger.info('User aborted timeout');
                  setConfirmTimeoutOpen(false);
                } else {
                  logger.info('User has timed out. Logging out.');
                  logout();
                }
              }}
            />
          </AgreedToTerms>
        )}
      </SetAuthed>
    </MsalAuthenticationTemplate>
  );
};

export default ProtectedRoute;
