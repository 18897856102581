/* eslint no-console: "off" */

import { retrieveValueSync } from 'services/local';
import config from '../../config';

const logBit = retrieveValueSync('Goldie/ShowLogs');
const isDev = config.env === 'development';
const shouldLog = !isDev && logBit === 1;

const prodLogger = {
  info: (...args) => {
    if (shouldLog) {
      console.log(...args);
    }
  },
  error: (...args) => {
    if (shouldLog) {
      console.error(...args);
    }
  },
};

export default prodLogger;
