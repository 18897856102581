import React, { useState } from 'react';
import CheckboxSelection from '../../atoms/CheckboxSelection';
import { Button, ConfirmationDialog } from 'ui';

import { ReactComponent as DeleteIcon } from './delete.svg';

import './file-selector.css';

const mapProcessingText = (status: string, comment: string) => {
  if (status === 'error') return 'Error with file processing';
  if (status === 'rejected') return comment;
  return 'File processing...';
};

const FileSelector = ({
  fileList,
  selectedFileIds,
  setSelectedFiles,
  deleteFile,
  refreshFiles,
}) => {
  const [error, setError] = useState<string>('');
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const deleteFiles = async (fileIds: string[]) => {
    const promises = fileIds.map((fileId) => {
      return deleteFile(fileId);
    });
    await Promise.all(promises);
    setSelectedFiles([]);
    refreshFiles();
    setConfirmDelete(false);
  };

  return (
    <div className="file-selector" data-testid="test-file-selector">
      {error && <div className="error">{error}</div>}
      {fileList.length > 0 && (
        <>
          <Button
            type="link"
            aria-label="Delete File(s)"
            className="ant-modal-round-button delete-button"
            icon={
              <DeleteIcon
                onClick={() => {
                  if (selectedFileIds.length > 0) {
                    setConfirmDelete(true);
                  }
                }}
              />
            }
          ></Button>
          {confirmDelete && (
            <ConfirmationDialog
              text="Are you sure you want to delete these files?"
              open={confirmDelete}
              confirmText="Delete"
              cancelText="Cancel"
              reportResponse={(hasConfirmed) => {
                if (hasConfirmed) {
                  deleteFiles(selectedFileIds);
                } else {
                  setConfirmDelete(false);
                }
              }}
            />
          )}
        </>
      )}
      <ul className="file-list-all">
        {fileList.length > 0 && (
          <li className="all">
            <CheckboxSelection
              label="Uploaded documents"
              value="all"
              isChecked={selectedFileIds.length === fileList.length}
              setSelected={(e) => {
                if (e.target.checked) {
                  setSelectedFiles(fileList.map((file) => file.id));
                } else {
                  setSelectedFiles([]);
                }
              }}
            />
          </li>
        )}
      </ul>
      <ul className="file-list">
        {fileList.map((file) => (
          <li className="file-list-item" key={`file-select-${file.id}`}>
            <CheckboxSelection
              className={
                file.processingStatus === 'done'
                  ? 'file-available'
                  : 'file-unavailable'
              }
              value={file.id}
              label={file.displayFileName}
              sublabel={
                file.processingStatus !== 'done'
                  ? mapProcessingText(
                      file.processingStatus,
                      file.processingFailureComment,
                    )
                  : ''
              }
              isChecked={selectedFileIds.includes(file.id)}
              setSelected={(e) => {
                if (e.target.checked) {
                  setSelectedFiles([...selectedFileIds, file.id]);
                } else {
                  setSelectedFiles(
                    selectedFileIds.filter((id) => id !== file.id),
                  );
                }
                setError('');
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileSelector;
