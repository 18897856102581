import { Store } from 'pullstate';
import api from 'services/api';
import { sortByName } from 'utils/sort-methods';

export enum AccessLevel {
  Personal = 'private',
  Internal = 'beta-test only',
  Public = 'stryker-wide',
}

type Organization = {
  id: string;
  name: string;
};

export const IDLE_TIMEOUT = 7200000; // 2 hours in milliseconds
export const IDLE_TIMEOUT_CONFIRMATION = 2 * 60 * 1000; // 2 minutes and 00 seconds in milliseconds

const ORGANIZATIONS: Organization[] = sortByName([
  { id: 'JR - Hips', name: 'JR - Hips' },
  { id: 'JR - Knees', name: 'JR - Knees' },
  { id: 'T&E - Foot & Ankle', name: 'T&E - Foot & Ankle' },
  { id: 'T&E - Trauma', name: 'T&E - Trauma' },
  { id: 'T&E - Upper Extremeties', name: 'T&E - Upper Extremeties' },
  { id: 'Spine - Core Spine', name: 'Spine - Core Spine' },
  { id: 'Spine - Enabling Tech', name: 'Spine - Enabling Tech' },
  { id: 'Medical - Acute Care', name: 'Medical - Acute Care' },
  { id: 'Medical - Emergency Care', name: 'Medical - Emergency Care' },
  { id: 'Medical - Sage', name: 'Medical - Sage' },
  { id: 'Endo - Communications', name: 'Endo - Communications' },
  { id: 'Endo - Endo BU', name: 'Endo - Endo BU' },
  { id: 'Endo - Sports Medicine', name: 'Endo - Sports Medicine' },
  {
    id: 'Endo - Sustainability Solutions',
    name: 'Endo - Sustainability Solutions',
  },
  { id: 'Instruments - Neuro Surgical', name: 'Instruments - Neuro Surgical' },
  {
    id: 'Instruments - Orthopedic Instruments',
    name: 'Instruments - Orthopedic Instruments',
  },
  { id: 'Instruments - CMF', name: 'Instruments - CMF' },
  {
    id: 'Instruments - Surgical Technologies',
    name: 'Instruments - Surgical Technologies',
  },
  { id: 'Instruments - ENT', name: 'Instruments - ENT' },
  { id: 'SGTC', name: 'SGTC' },
  { id: 'Neurovascular', name: 'Neurovascular' },
  {
    id: 'DRE - Robotics & Enabling Tech',
    name: 'DRE - Robotics & Enabling Tech',
  },
  { id: 'DRE - Digital', name: 'DRE - Digital' },
  { id: 'DRE - AI', name: 'DRE - AI' },
  { id: 'Corporate', name: 'Corporate' },
  { id: 'OUS Sales', name: 'OUS Sales' },
  { id: 'IT', name: 'IT' },
  { id: 'Other', name: 'Other' },
]);

const MAX_FILE_SIZE = 15000000; // 15MB

const FILE_TYPES = [
  {
    label: 'PPTX',
    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  },
  {
    label: 'DOCX',
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  {
    label: 'TXT',
    type: 'text/plain',
  },
  {
    label: 'PDF',
    type: 'application/pdf',
  },
];

type SiteMetadata = {
  organizations: Organization[];
  gptModels: { id: string; name: string }[];
  fileUploadSizeLimit?: number;
  fileUploadFileTypes?: { label: string; type: string }[];
};

const initialState: SiteMetadata = {
  organizations: [],
  gptModels: [],
};

export const MetadataStore = new Store(initialState);

export const initMetadata = async () => {
  const gptModels = await api.getGptModels();
  MetadataStore.update((s) => {
    s.organizations = ORGANIZATIONS;
    s.gptModels = gptModels;
    s.fileUploadFileTypes = FILE_TYPES;
    s.fileUploadSizeLimit = MAX_FILE_SIZE;
    return s;
  });
};

export const resetStore = () => {
  MetadataStore.update((s) => {
    return initialState;
  });
};
