import { useEffect } from 'react';
import { LS_THEME_KEY } from 'stores/app-context';
import { setGlobalMessage } from 'stores/global-message';

const PermanentToastPage = ({ children, message }) => {
  const theme = localStorage.getItem(LS_THEME_KEY) ?? 'light';
  useEffect(() => {
    document.body.classList.add(`-theme-${theme}`);
    setGlobalMessage(message, 'permanent');
  }, [message, theme]);
  return <>{children}</>;
};

export default PermanentToastPage;
