import { Store } from 'pullstate';
import { getInitials } from '../utils/initials';
import api from 'services/api';
import { persistSessionValue, retrieveSessionValue } from 'services/local';
import logger from 'services/logger';

type UserInfo = {
  name: string;
  email: string;
  initials: string;
};

type UserStoreType = {
  requestedAuth: boolean;
  isAuthed: boolean;
  userHasLoaded: boolean;
  apiConfig: any;
  personalFiles: any[];
  userInfo: UserInfo | null;
  agreedToTerms: boolean;
};

const initialState: UserStoreType = {
  requestedAuth: false,
  isAuthed: false,
  userHasLoaded: false,
  apiConfig: null,
  personalFiles: [],
  userInfo: null,
  agreedToTerms: false,
};

export const UserStore = new Store(initialState);

export const loginAction = async () => {
  UserStore.update((s) => {
    s.requestedAuth = true;
    return s;
  });
};

export const setUserHasLoaded = async (
  isAuthed: boolean,
  apiConfig: any,
  accountInfo: any,
) => {
  const agreedToTerms = await retrieveSessionValue('agreedToTerms');
  await UserStore.update((s) => {
    s.isAuthed = isAuthed;
    s.apiConfig = apiConfig;
    s.userHasLoaded = true;
    s.userInfo = {
      name: accountInfo?.name || '',
      email: accountInfo?.username || '',
      initials: getInitials(accountInfo?.name || ''),
    };
    s.agreedToTerms = agreedToTerms === 'true';
    return s;
  });

  persistSessionValue('isAuthed', 'true');
};

export const logoutAction = async () => {
  persistSessionValue('isAuthed', null);
  UserStore.update((s) => {
    s.requestedAuth = false;
    s.apiConfig = null;
    s.isAuthed = false;
    return s;
  });

  await setAgreedToTerms(false);
};

export const initPersonalFiles = async () => {
  try {
    const files = await api.getFileList();
    if (files && Array.isArray(files)) {
      UserStore.update((s) => {
        s.personalFiles = files;
        return s;
      });
    }
  } catch (e) {
    logger.error('Error fetching personal files', e);
  }
};

export const setAgreedToTerms = async (agreedToTerms: boolean) => {
  persistSessionValue('agreedToTerms', agreedToTerms.toString());
  UserStore.update((s) => {
    s.agreedToTerms = agreedToTerms;
    return s;
  });
};

(async () => {
  const isAuthed = await retrieveSessionValue('isAuthed');
  const agreedToTerms = await retrieveSessionValue('agreedToTerms');
  if (isAuthed === 'true') {
    UserStore.update((s) => {
      s.isAuthed = true;
      s.agreedToTerms = agreedToTerms === 'true';
      return s;
    });
  }
})();
