import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import useSilentRequest from 'services/hooks/useSilentRequest';
import { loginAction } from 'stores/user';
import { Button, GoldieLogo, StrykerLogo } from 'ui';

import './login.css';

const Login = () => {
  let navigate = useNavigate();
  let { account } = useSilentRequest();

  useEffect(() => {
    account && navigate('/home');
  }, [account, navigate]);

  const login = () => {
    loginAction().then(() => {
      navigate('/terms');
    });
  };
  return (
    <div className="login">
      <div className="login-content">
        <StrykerLogo />
        <GoldieLogo />
        <Button onClick={login}>Sign in with Stryker</Button>
      </div>
    </div>
  );
};

export default Login;
