import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

import { Modal, Button, RadioSelection } from 'ui/atoms';
import { AppContextStore, LS_THEME_KEY } from 'stores/app-context';
import { logoutAction, UserStore } from 'stores/user';
import { openPublicFile } from 'utils/open-public-file';
import { VERSION } from 'version';
import IssueLogger from './issue-logger';

import { ReactComponent as IconSignOut } from './icons/icon-sign-out.svg';
import { ReactComponent as IconTOU } from './icons/icon-tou.svg';
import { ReactComponent as IconPrivacyPolicy } from './icons/icon-privacy-policy.svg';
import { ReactComponent as IconVersionNumber } from './icons/icon-version-number.svg';

import './settings.css';

export type Theme = 'dark' | 'light' | 'system';

const availableThemes: { label: string; value: Theme }[] = [
  {
    label: 'Light Mode',
    value: 'light',
  },
  {
    label: 'Dark Mode',
    value: 'dark',
  },
  {
    label: 'System',
    value: 'system',
  },
];

export function returnThemeIfSystem(theme: any) {
  if (theme === 'system') {
    return handleSystemTheme();
  }

  return theme;
}

export function handleSystemTheme(): Theme {
  if (!window.matchMedia) {
    return 'dark';
  }

  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
}

export function SettingsMenuModal({
  toggleSettingsMenu,
  setToggleSettingsMenu,
  setTheme,
}) {
  const [currentMenuTheme, setCurrentMenuTheme] = useState<Theme>('light');
  const account = UserStore.useState((s) => s.userInfo?.email);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    const menuThemeFromLS = localStorage.getItem(LS_THEME_KEY) as Theme;

    if (menuThemeFromLS) {
      return setCurrentMenuTheme(menuThemeFromLS);
    }

    setCurrentMenuTheme('light');
    setTheme('light');
  }, [setToggleSettingsMenu, setTheme]);

  async function logout() {
    logoutAction();
    const currentAccount = accounts[0];

    await instance.logoutRedirect({
      account: currentAccount,
    });
  }

  function updateCurrentTheme(event: React.FormEvent<HTMLInputElement>) {
    const selectedTheme = event.currentTarget.value;
    const themeToApply = returnThemeIfSystem(selectedTheme);

    setCurrentMenuTheme(selectedTheme as Theme);
    setTheme(themeToApply);

    localStorage.setItem(LS_THEME_KEY, selectedTheme);
  }

  return (
    <Modal
      title="Settings"
      open={toggleSettingsMenu}
      onCancel={() => {
        setToggleSettingsMenu(false);
      }}
      footer={() => {}}
    >
      <section className="settings-section">
        <h3>Account</h3>
        <span className="account-name">{account}</span>
      </section>

      <section className="settings-section">
        <h3>Color Scheme</h3>
        <div className="settings-toggle-theme">
          {availableThemes.map(({ value, label }) => {
            return (
              <RadioSelection
                key={value}
                label={label}
                name="themeSelection"
                value={value}
                checked={currentMenuTheme === value}
                onChange={updateCurrentTheme}
                cssClasses="single-line"
              />
            );
          })}
        </div>
      </section>

      <section className="settings-section -disabled">
        <h3>About</h3>
        <AboutSection />
      </section>

      <IssueLogger />

      <section className="settings-sign-out -disabled">
        <Button
          type="text"
          className="sign-out-button"
          icon={<IconSignOut />}
          onClick={logout}
        >
          Sign Out
        </Button>
      </section>
    </Modal>
  );
}

function AboutSection() {
  const apiVersion = AppContextStore.useState((s) => s.apiVersion);
  return (
    <div className="button-list">
      <Button
        type="text"
        icon={<IconTOU />}
        onClick={() => openPublicFile('terms-of-services.pdf')}
      >
        Terms of Services
      </Button>

      <Button
        type="text"
        icon={<IconPrivacyPolicy />}
        onClick={() => openPublicFile('privacy-policy.pdf')}
      >
        Privacy Policy
      </Button>

      <div className="button-list-row">
        <Button type="text" icon={<IconVersionNumber />}>
          App Version
        </Button>
        <span className="version-label">{VERSION}</span>
      </div>

      <div className="button-list-row">
        <Button type="text" icon={<IconVersionNumber />}>
          Api Version
        </Button>
        <span className="version-label">{apiVersion}</span>
      </div>
    </div>
  );
}
