import { useState, useEffect } from 'react';

import {
  deleteConversation,
  setCurrentConversation,
  renameConversation,
} from 'stores/current-conversation';
import { loadConversationHistory } from 'stores/conversation-history';
import { setGlobalError } from 'stores/global-message';
import { ConversationHistoryItem } from 'ui';
import logger from 'services/logger';

import './conversation-history.css';

export type Conversation = {
  conversationName: string;
  datetimeCreated: string;
  datetimeLastUpdated: string;
  id: string;
  mostRecentMessageIds: string[];
  newestMessageId: string;
  oldestMessageId: string;
  userEmail?: string;
};

export function mapConversationNames(list: Conversation[]) {
  return list.map((m: Conversation) => {
    if (m.conversationName) {
      return m;
    }

    return {
      ...m,
      conversationName: 'Untitled Conversation',
    };
  });
}

const ConversationHistory = ({ title, list }) => {
  const [titledList, setTitledList] = useState(list);

  useEffect(() => {
    setTitledList(mapConversationNames(list));
  }, [list]);

  return (
    <div className="conversation-history">
      <h2>{title}</h2>
      <div className="history-list">
        {titledList.map((m: Conversation) => (
          <ConversationHistoryItem
            key={`historyitem_${m.id}`}
            id={m.id}
            text={m.conversationName}
            deleteConversation={() => {
              // eslint-disable-next-line no-restricted-globals
              const confirmed = confirm(
                'Are you sure you want to delete this conversation?',
              );
              if (confirmed) {
                deleteConversation(m.id);
                loadConversationHistory();
                setCurrentConversation();
              }
            }}
            setCurrentConversation={setCurrentConversation}
            renameConversation={(id: string, newName: string) => {
              return renameConversation(id, newName)
                .then(() => {
                  loadConversationHistory();
                  return true;
                })
                .catch((e) => {
                  logger.error(e);
                  setGlobalError('Failed to rename conversation');
                  return false;
                });
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ConversationHistory;
