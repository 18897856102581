import React, { useEffect, useState } from 'react';
import { Button, Modal } from '../../atoms';
import FileSelector from './FileSelector';
import {
  CurrentConversationStore,
  setFileContexts,
} from 'stores/current-conversation';
import api from 'services/api';
import { initPersonalFiles } from 'stores/user';
import logger from 'services/logger';
import { setGlobalMessage } from 'stores/global-message';

let fileInterval;

const FileSelectorModal = ({
  fileSelectorVisible,
  setFileSelectorVisible,
  personalFiles,
}) => {
  const fileContexts = CurrentConversationStore.useState((s) => s.fileContexts);
  const [tempFileContexts, setTempFileContexts] = useState(fileContexts);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  useEffect(() => {
    if (fileSelectorVisible) {
      initPersonalFiles();
      if (!fileInterval) {
        fileInterval = setInterval(() => {
          initPersonalFiles();
        }, 5000);
      }
    } else {
      clearInterval(fileInterval);
      fileInterval = undefined;
    }
  }, [fileSelectorVisible]);

  useEffect(() => {
    setTempFileContexts(fileContexts);
  }, [fileContexts]);

  useEffect(() => {
    if (!personalFiles || !Array.isArray(personalFiles)) {
      setIsValid(true);
      return;
    }
    const loadingOrErrorFilesSelected =
      personalFiles.filter(
        (m) => m.processingStatus !== 'done' && tempFileContexts.includes(m.id),
      ) || [];

    setIsValid(loadingOrErrorFilesSelected.length === 0);
  }, [personalFiles, tempFileContexts]);

  useEffect(() => {
    if (fileSelectorVisible) {
      if (!Array.isArray(fileContexts) || !Array.isArray(tempFileContexts)) {
        setIsDirty(false);
        return;
      }

      const sortedFileContexts = [...fileContexts].sort((a, b) =>
        a > b ? 1 : -1,
      );
      const sortedTempFileContexts = [...tempFileContexts].sort((a, b) =>
        a > b ? 1 : -1,
      );

      setIsDirty(
        JSON.stringify(sortedFileContexts) !==
          JSON.stringify(sortedTempFileContexts),
      );
    }
  }, [fileContexts, fileSelectorVisible, tempFileContexts]);

  return (
    <Modal
      title="Select Files"
      className="select-file-modal"
      open={fileSelectorVisible}
      onOk={() => {
        setFileSelectorVisible(false);
      }}
      onCancel={() => {
        setTempFileContexts(fileContexts);
        setFileSelectorVisible(false);
      }}
      footer={[
        <Button
          className="active full-size"
          key="fileselector_apply"
          data-testid="fileselector_apply"
          onClick={() => {
            setFileContexts(tempFileContexts);
            setFileSelectorVisible(false);
            setGlobalMessage('Files successfully applied');
          }}
          disabled={!isValid || !isDirty}
        >
          Apply
        </Button>,
      ]}
    >
      <FileSelector
        fileList={personalFiles}
        selectedFileIds={tempFileContexts}
        setSelectedFiles={setTempFileContexts}
        deleteFile={(fileId) => {
          api.deleteFile(fileId).catch((e) => {
            logger.error(e);
          });
        }}
        refreshFiles={() => {
          initPersonalFiles();
        }}
      />
      {isDirty && isValid && (
        <div className="info" data-testid="file-selection-change-info-message">
          There are changes that have not been applied
        </div>
      )}
      {!isValid && (
        <div
          className="warning"
          data-testid="file-selection-change-warning-message"
        >
          Invalid file selected. Changes cannot be applied.
        </div>
      )}
    </Modal>
  );
};

export default FileSelectorModal;
