import { Button, Card, ContextButton } from '../../ui';
import { useNavigate } from 'react-router-dom';

import './contexts.css';

const contextItems = [
  {
    id: 1,
    name: 'Product IFUs',
  },
  {
    id: 2,
    name: 'Legal documents',
  },
  {
    id: 3,
    name: 'Regulatory documents',
  },
];

const Contexts = () => {
  let navigate = useNavigate();
  const proceed = () => {
    navigate('/home');
  };

  return (
    <div className="contexts">
      <div className="card-wrapper">
        <Card>
          <article>
            <h1>Select Contexts</h1>
            <p>
              Pick contexts below to better tailer Stryker Goldie's repsonses to
              your prompts
            </p>
            <div className="context-list">
              {contextItems.map((item) => {
                return (
                  <div key={item.id}>
                    <ContextButton
                      setSelected={() => {}}
                      isSelected={false}
                      title={`Context ${item.id}`}
                      text={item.name}
                      value={item.id}
                    ></ContextButton>
                  </div>
                );
              })}
            </div>
          </article>
          <Button onClick={proceed}>Proceed</Button>
        </Card>
      </div>{' '}
    </div>
  );
};

export default Contexts;
