import { MsalAuthenticationResult } from '@azure/msal-react';
import { useEffect } from 'react';
import { setGlobalError } from '../../stores/global-message';
import logger from '../../services/logger';
import { useNavigate } from 'react-router-dom';

function MsalAuthenticationError({ error }: MsalAuthenticationResult) {
  const navigate = useNavigate();
  useEffect(() => {
    logger.error(error);
    navigate('/');
    setGlobalError('Unable to authenticate');
  }, [error, navigate]);

  return <></>;
}

export default MsalAuthenticationError;
