import React, { useEffect } from 'react';
import classNames from 'classnames';
import {
  AppContextStore,
  setIsSidebarCollapsed,
  setTheme,
} from 'stores/app-context';
import { Conversation, Drawer, Button, Loading } from 'ui';
import ConversationHistory from 'ui/organisms/ConversationHistory';
import Settings from 'ui/organisms/Settings';
import GoldieLogo from 'ui/molecules/GoldieLogo';
import {
  ConversationHistoryStore,
  loadConversationHistory,
} from 'stores/conversation-history';
import { setCurrentConversation } from 'stores/current-conversation';
import { UserStore } from 'stores/user';
import { initMetadata } from 'stores/metadata';

import { ReactComponent as IconCollapse } from './icon-collapse.svg';
import { ReactComponent as IconPlus } from './icon-plus.svg';

import './home.css';

const Home = () => {
  const userHasLoaded = UserStore.useState((s) => s.userHasLoaded);
  const isCollapsed = AppContextStore.useState((s) => s.isSidebarCollapsed);
  const { history, historyHasLoaded } = ConversationHistoryStore.useState(
    (s) => ({
      history: s.history,
      historyHasLoaded: s.hasLoaded,
    }),
  );
  const isMessagesScrolling = AppContextStore.useState(
    (s) => s.isMessagesWindowScrolling,
  );

  useEffect(() => {
    if (userHasLoaded) {
      loadConversationHistory();
      initMetadata();
    }
  }, [userHasLoaded]);

  useEffect(() => {
    if (isCollapsed) {
      document.body.classList.remove('drawer-open');
    } else {
      document.body.classList.add('drawer-open');
    }
  }, [isCollapsed]);

  return (
    <main
      data-testid="home-container"
      className={classNames(['home', { '-collapsed': isCollapsed }])}
    >
      <Drawer isCollapsed={isCollapsed}>
        <div className="logo-container">
          <GoldieLogo />
          <button
            data-testid="drawer-collapse-button"
            aria-label="Collapse Menu"
            className="collapse-control -mobile"
            onClick={() => setIsSidebarCollapsed(!isCollapsed)}
          >
            <IconCollapse />
          </button>
        </div>
        <div className="new-conversation-button">
          <Button
            type="outline"
            icon={<IconPlus />}
            onClick={() => {
              setCurrentConversation();
            }}
          >
            New Chat
          </Button>
        </div>
        <div className="conversation-histories">
          {!historyHasLoaded && <Loading />}
          {historyHasLoaded && history && history.length > 0 && (
            <>
              {history.map((h) => {
                if (h.history.length === 0) return null;
                return (
                  <ConversationHistory
                    key={`cconversation_history_${h.id}`}
                    title={h.title}
                    list={h.history}
                  />
                );
              })}
            </>
          )}
        </div>
      </Drawer>
      <article
        id="main-content"
        className={`${isMessagesScrolling ? '-scrolling' : ''}`}
      >
        <Settings setTheme={setTheme}>
          <button
            data-testid="drawer-collapse-button"
            aria-label="Collapse Menu"
            className="collapse-control"
            onClick={() => setIsSidebarCollapsed(!isCollapsed)}
          >
            <IconCollapse />
          </button>
        </Settings>

        <Conversation />
      </article>
    </main>
  );
};

export default Home;
