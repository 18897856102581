import devLogger from './logger-transports/devLogger';
import prodLogger from './logger-transports/prodLogger';

export const debug = (...logs) => {
  devLogger.debug(...logs);
  prodLogger.info(...logs);
};

export const log = (...logs) => {
  devLogger.info(...logs);
  prodLogger.info(...logs);
};

export const error = (...logs) => {
  devLogger.error(...logs);
  prodLogger.error(...logs);
};

const logger = {
  log,
  error,
  info: log,
  debug,
};

export default logger;
