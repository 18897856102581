import markdownit from 'markdown-it';
import DOMPurify from 'dompurify';

import './markdown-renderer.css';
import './theme-dark.css';
import './theme-light.css';

const markdownItConfig = {
  html: false,

  // Autoconvert URL-like text to links
  linkify: false,

  // Convert '\n' in paragraphs into <br>
  breaks: true,

  // Enable some language-neutral replacement + quotes beautification
  typographer: true,

  // CSS language prefix for fenced blocks. Can be
  // useful for external highlighters.
  langPrefix: 'language-',
};

type MarkdownRendererProps = {
  text: string | undefined | null;
};

export const MarkdownRenderer = ({ text = '' }: MarkdownRendererProps) => {
  const md = markdownit(markdownItConfig)
    .use(require('markdown-it-highlightjs'), {
      inline: false,
    })
    .disable(['image']);

  const sanitizedText = DOMPurify.sanitize(String(text));
  const parsedMarkdown = md.render(sanitizedText);

  return (
    <div className="markdown-container">
      <div
        className="markdown"
        data-testid="markdown"
        dangerouslySetInnerHTML={{ __html: parsedMarkdown }}
      />
    </div>
  );
};
